import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/components/contact.scss"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <div className="contact">
      <h1 className="contact__title">Contact Me</h1>
      <div className="contact__content">
        <form
          method="post"
          className="contact__form"
          data-netlify="true"
          netlify-honeypot="bot-field"
          name="Website submissions"
        >
          <input type="hidden" name="form-name" value="Website submissions" />
          <input type="hidden" name="bot-field" />
          <p>
            <label>
              Name <input type="text" name="name" />
            </label>
          </p>
          <p>
            <label>
              Email <input type="email" name="email" />
            </label>
          </p>
          <p>
            <label>
              Message <textarea name="message" rows="10"></textarea>
            </label>
          </p>
          <p>
            <button type="submit">Send</button>
          </p>
        </form>
        <p>I am also available to contact through the following services:</p>
        <div className="contact__links">
          <div className="contact__link contact__link--tw">
            <a href="https://twitter.com/HazleyStuart">Twitter</a>
          </div>
          <div className="contact__link contact__link--ins">
            <a href="https://www.instagram.com/stuarthazleyfoundry/">
              Instagram
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ContactPage
